<script type="text/javascript" src="cordova.js"></script>
<script>
import Layout from "../../layouts/main";
import PageHeader from "@/components/page-header";
import appConfig from "@/app.config";
import Swal from "sweetalert2";
//import InputTag from 'vue-input-tag';
//import { VueEditor } from "vue2-editor";
//import Basic from "./basic";
import axios from 'axios';

import {
  required,
  email,
  //minLength,
  //sameAs,
  //maxLength,
  //minValue,
  //maxValue,
  //numeric,
  //url,
  //alphaNum
} from "vuelidate/lib/validators";

/**
 * Form validation component
 */
export default {
  page: {
    title: "Profile",
    meta: [{ name: "description", content: appConfig.description }]
  },
  components: { Layout, PageHeader, /*InputTag, VueEditor Basic*/ },
  props:{
    id:[Number,null],
  },
  data() {
    return {
      title: "Profile",
      componentKey: 0,
      num_lat: 0.0,
      num_lng: 0.0,
      items: [
        {
          text: "Account",
          href: "/"
        },
        {
          text: "Profile",
          active: true
        }
      ],
      server:                 "",
      platform:               "",
      user:{
        user:{
          nm_employee:              "",
          nm_lastName:              "",
          num_employeeAge:          "",
          id_gender:                "",
          de_email:                 "",
          num_employeeCompanyPhone: "",
          url_facebook:             "",
          url_twitter:              "",
          url_instagram:            "",
          url_linkedIn:             "",
          url_youtube:              "",
          dt_suscriptionDate:       "",
        }
      },
      submitted: false,
    };
  },
  validations: {
    user: {
      user:{
        nm_employee:              {required },
        nm_lastName:              { required },
        num_employeeAge:          { required },
        id_gender:                { required },
        de_email:                 { required, email },
        num_employeeCompanyPhone: { required }
      }
    }
  },
  watch:{},
  created(){
    if(localStorage){
      this.user = JSON.parse( localStorage.getItem('user') );
    }
  },
  mounted() {
    this.server = window.serverPath;
    document.addEventListener("deviceready", this.onDeviceReady, false);
  },
  methods: {
    onDeviceReady(){
      let platform = device.platform;

      if(platform){
        this.platform = platform;
      }
    },
    // eslint-disable-next-line no-unused-vars
    saveProfile(){
      let app = this;
      let employee = {};

      //this.submitted = true;
      // stop here if form is invalid
      this.$v.$touch();
      
      if( !this.$v.user.user.$error ){
        Swal.fire({
          title: "Saving profile.",
          showConfirmButton:false,
          onBeforeOpen: () => {
            Swal.showLoading();
          }
        });

        employee = {
          id_employee:              this.user.user.id_employee,
          nm_employee:              this.user.user.nm_employee,
          nm_lastName:              this.user.user.nm_lastName,
          num_employeeAge:          this.user.user.num_employeeAge,
          id_gender:                this.user.user.id_gender,
          de_employeeCompanyEmail:  this.user.user.de_employeeCompanyEmail,
          num_employeeCompanyPhone: this.user.user.num_employeeCompanyPhone
        }

        axios.post(window.serverPath+'dist/mvw/controllers/employees.php', {
          request: 'saveEmployee',
          data: employee,
          token: this.user.token,
        })
        .then(function (response) {
          if(response.status == 200){
            Swal.close();
            if( response.error ){
              Swal.fire("Ooops!" + response.error, "Seems like some error ocurr, please try again.", "error");
            }else{          
              app.user.user.nm_employee                = employee.nm_employee;
              app.user.user.nm_lastName                = employee.nm_lastName;
              app.user.user.num_employeeAge            = employee.num_employeeAge;
              app.user.user.id_gender                  = employee.id_gender;
              app.user.user.de_employeeCompanyEmail    = employee.de_employeeCompanyEmail;
              app.user.user.num_employeeCompanyPhone   = employee.num_employeeCompanyPhone;

              localStorage.setItem('user', JSON.stringify(app.user) );
              Swal.fire("Good job!", "Your profile data is correct saved!", "success");
            }
          }
        })
        .catch(function (error) {
          Swal.fire("Ooops!" + error.message, "Seems like some error ocurr, please try again.", "error");
        });
      }
    },

    saveSocialNetworks(){
      let app = this;
      let socialNetworks = {};

      Swal.fire({
          title: "Saving company social networks.",
          showConfirmButton:false,
          onBeforeOpen: () => {
            Swal.showLoading();
          }
        });

        socialNetworks = {
          id_company:     this.user.user.id_company,
          url_facebook:   this.user.user.url_facebook,
          url_twitter:    this.user.user.url_twitter,
          url_instagram:  this.user.user.url_instagram,
          url_youtube:    this.user.user.url_youtube,
          url_linkedIn:   this.user.user.url_linkedIn
        };

        axios.post(window.serverPath+'dist/mvw/controllers/organizations.php', {
          request: 'saveOrganization',
          data: socialNetworks,
          token: this.user.token,
        })
        .then(function (response) {
          if(response.status == 200){
            Swal.close();
            if( response.error ){
              Swal.fire("Ooops!" + response.error, "Seems like some error ocurr, please try again.", "error");
            }else{
              app.user.user.url_facebook    = socialNetworks.url_facebook;
              app.user.user.url_twitter     = socialNetworks.url_twitter;
              app.user.user.url_instagram   = socialNetworks.url_instagram;
              app.user.user.url_youtube     = socialNetworks.url_youtube;
              app.user.user.url_linkedIn    = socialNetworks.url_linkedIn;

              localStorage.setItem('user', JSON.stringify(app.user) );
              Swal.fire("Good job!", "Your profile data is correct saved!", "success");
            }
          }
        })
        .catch(function (error) {
          Swal.fire("Ooops!" + error.message, "Seems like some error ocurr, please try again.", "error");
        });
    },

    clickFile(){
      this.$refs.url_photograph.click();
    },
    handleFile(event){
      let app = this;
      let output = this.$refs.picture;
      let formData = new FormData();

      output.src = URL.createObjectURL(event.target.files[0]);
      output.onload = function() {
        URL.revokeObjectURL(output.src); // free memory
      }

      formData.append('request',        'changePicture');
      formData.append('token',          this.user.token);
      formData.append('data',           JSON.stringify(this.user.user) );
      formData.append('url_photograph', event.target.files[0]);

      axios.post(window.serverPath+'dist/mvw/controllers/users.php', formData, {
        headers: {
          'Content-Type': 'multipart/form-data'
        }
      })
      .then(function (response) {
        if(response.status == 200){
          Swal.close();
          if( response.error ){
            Swal.fire("Ooops!" + response.error, "Seems like some error ocurr, please try again.", "error");
          }else{
            app.user.user.url_photograph = response.data;
            localStorage.setItem('user', JSON.stringify(app.user));
            Swal.fire("Good job!", "Your profile data is correct saved!", "success").then(function(response){
              if(response.isConfirmed){
               app.$router.go();
              }
            });
          }
        }
      })
      .catch(function (error) {
        Swal.fire("Ooops!" + error.message, "Seems like some error ocurr, please try again.", "error");
      });
    },

    openURL(url){
      //cordova.InAppBrowser.open(url, '_blank', 'location=yes,hidden=no,fullscreen=yes,clearcache=yes,clearsessioncache=yes,closebuttoncolor=#000000,hideurlbar=yes,zoom=no');
      if(this.platform != "" ){
        navigator.app.loadUrl(url, { openExternal: true });
      }
    }
  }
};
</script>

<template>
  <Layout>
    <PageHeader :title="title" :items="items" />

    <form class="needs-validation" @submit.prevent="saveOrganization">

      <!--
      <div class="row" v-if="!isEditState">
        <div class="col-lg-12">
          <div class="email-leftbar card">
            <img src="@/assets/images/evalmatters-logo.png"/>
            <p class="card-title-desc text-center m-0"><b>Eval with sense</b></p>
            <p class="card-title-desc text-center m-0"><small>Ensenada, B.C. Mexico</small></p>

            <div class="card"></div>

            <div class="mail-list mt-3">
              <div class="card-title" style="border-bottom:solid 1px #C8C8C8"><b>About</b></div>
              <p>THIS IS MY COMPANY, IT WAS CREATED BECAUSE OF THE NEED TO KEEP WELL FEED OF MY 3 KIDS</p>
              

              <div class="card-title" style="border-bottom:solid 1px #C8C8C8"><b>Contact Information</b></div>
              <p>
                <small><i class="far fa-envelope"></i> contact@mycompany.com.mx</small><br>
                <small><i class="fas fa-phone-alt"></i> +52 (646) 1114859</small><br><br>
                <small>Ensenada B.C., Mexico. Punta Banda Colony #373 Dept 3, 2nd floor.</small>
              </p>

              <div class="card-title" style="border-bottom:solid 1px #C8C8C8"><b>Specialists on</b></div>
              <span v-for="tag in form.de_companyTags" :vid-id="tag" :key="tag" class="badge ml-1 badge-success badge-pill">{{tag}}</span>
            </div>
          </div>

          <div class="card email-rightbar">
            <div class="card-body">
              <b-tabs content-class="p-3 text-muted">
                <b-tab active class="border-0">
                  <template v-slot:title>
                    <span class="d-inline-block d-sm-none">
                      <i class="fas fa-home"></i>
                    </span>
                    <span class="d-none d-sm-inline-block">History</span>
                  </template>
                  <p v-html="form.de_companyHistory"></p>
                </b-tab>

                <b-tab>
                  <template v-slot:title>
                    <span class="d-inline-block d-sm-none">
                      <i class="far fa-user"></i>
                    </span>
                    <span class="d-none d-sm-inline-block">Industry</span>
                  </template>
                  <p v-html="form.de_companyIndustry"></p>
                </b-tab>

                <b-tab>
                  <template v-slot:title>
                    <span class="d-inline-block d-sm-none">
                      <i class="far fa-envelope"></i>
                    </span>
                    <span class="d-none d-sm-inline-block">Capabilities</span>
                  </template>
                  <p v-html="form.de_companyCapabilities"></p>
                </b-tab>

                <b-tab>
                  <template v-slot:title>
                    <span class="d-inline-block d-sm-none">
                      <i class="fas fa-cog"></i>
                    </span>
                    <span class="d-none d-sm-inline-block">Social Networks</span>
                  </template>
                  <p></p>
                </b-tab>
              </b-tabs>
            </div>
          </div>
        </div>
      </div>
      -->

      <div class="row">
        <div class="email-leftbar card ml-3">
          <div class="col-sm-12">
            <div class="text-center" style="position: relative;">
              <img :src="server+'dist/'+user.user.url_photograph" ref="picture" :class="{ 'circular--portrait': !platform, 'circular--portrait--mobile': platform }" style="border: solid 5px #dfe6e9;" width="100%" />
              <div class="text-right">
                <img src="@/assets/images/camera_8.png" class="rounded-circle" width="20%" style="border: solid 3px #dfe6e9; margin-top:-50px; margin-right:20px; cursor:pointer; position: relative;" @click="clickFile"/>
                <input type="file" ref="url_photograph" name="url_photograph" style="display: none;" @change="handleFile">
              </div>
            </div>

            <h3 class="text-center mt-3 m-0">{{user.user.nm_employee}}</h3>
            <h5 class="text-center">{{user.user.nm_lastName}}</h5>
            <p class="text-center mt-3">
              <span v-if="user.user.de_email">{{user.user.de_email}}<br></span>
              <span v-if="user.user.num_employeeCompanyPhone">{{user.user.num_employeeCompanyPhone}}<br></span>
              <span v-if="user.user.num_employeeAge">{{user.user.num_employeeAge}} years old.<br></span>
              <span v-if="user.user.de_gender">{{user.user.de_gender}}<br></span>
            </p>
            <p class="text-center socialNetwork">
              <a v-if="user.user.url_facebook" :href="user.user.url_facebook" target="_blank"><i class="fab fa-facebook-square m-2" @click="openURL(user.user.url_facebook)"></i></a>
              <a v-if="user.user.url_twitter" :href="user.user.url_twitter" target="_blank"><i class="fab fa-twitter-square m-2" @click="openURL(user.user.url_twitter)"></i></a>
              <a v-if="user.user.url_instagram" :href="user.user.url_instagram" target="_blank"><i class="fab fa-instagram-square m-2" @click="openURL(user.user.url_instagram)"></i></a>
              <a v-if="user.user.url_linkedIn" :href="user.user.url_linkedIn" target="_blank"><i class="fab fa-linkedin m-2" @click="openURL(user.user.url_linkedIn)"></i></a>
              <a v-if="user.user.url_youtube" :href="user.user.url_youtube" target="_blank"><i class="fab fa-youtube-square m-2" @click="openURL(user.user.url_youtube)"></i></a>
            </p>
          </div>
        </div>

        <div class="card email-rightbar ml-4 col-sm-8">
          <div class="card-body">
            <div class="col-sm-12">
              <b-tabs content-class="p-3 text-muted">
                <b-tab>
                  <template v-slot:title>
                    <span class="d-sm-inline-block">
                      <i class="fas fa-user-circle"></i>
                      General data
                    </span>
                  </template>
                  <div class="row">
                    <div class="col-sm-4">
                      <div class="form-group">
                        <label for="nm_employee">Name</label>
                        <input
                          type="text"
                          class="form-control"
                          :class="{ 'is-invalid': submitted && $v.user.user.nm_employee.$error }"
                          name="nm_employee"
                          id="nm_employee"
                          v-model="user.user.nm_employee"
                          value=""
                        >
                        <div v-if="submitted && $v.user.user.nm_employee.$error" class="invalid-feedback">
                          <span v-if="!$v.user.user.nm_employee.required">Member name is required.</span>
                          <span v-if="!$v.user.user.nm_employee.minLength">This value length is invalid. It should be between 2 and 50 characters long.</span>
                          <span v-if="!$v.user.user.nm_employee.maxLength">This value length is invalid. It should be between 2 and 50 characters long.</span>
                        </div>
                      </div>

                      <div class="form-group">
                        <label for="nm_lastName">Member last name</label>
                        <input
                          type="text"
                          class="form-control"
                          :class="{ 'is-invalid': submitted && $v.user.user.nm_lastName.$error }"
                          name="nm_lastName"
                          id="nm_lastName"
                          v-model="user.user.nm_lastName"
                          value=""
                        >
                        <div v-if="submitted && $v.user.user.nm_lastName.$error" class="invalid-feedback">
                          <span v-if="!$v.user.user.nm_lastName.required">Member last name is required.</span>
                          <span v-if="!$v.user.user.nm_lastName.minLength">This value length is invalid. It should be between 2 and 50 characters long.</span>
                          <span v-if="!$v.user.user.nm_lastName.maxLength">This value length is invalid. It should be between 2 and 50 characters long.</span>
                        </div>
                      </div>
                    </div>
                    <div class="col-sm-4">
                      <div class="form-group">
                        <label for="num_employeeAge">Member age</label>
                        <input
                          type="text"
                          class="form-control"
                          :class="{ 'is-invalid': submitted && $v.user.user.num_employeeAge.$error }"
                          name="num_employeeAge"
                          id="num_employeeAge"
                          v-model="user.user.num_employeeAge"
                          value=""
                        />
                        <div v-if="submitted && $v.user.user.num_employeeAge.$error" class="invalid-feedback">
                          <span v-if="!$v.user.user.num_employeeAge.required">Member age is required.</span>
                          <span v-if="!$v.user.user.num_employeeAge.minValue">This minimum age is invalid. It should be between 18 and 100 years old.</span>
                          <span v-if="!$v.user.user.num_employeeAge.maxValue">This maximum age is invalid. It should be between 18 and 100 years old.</span>
                        </div>
                      </div>

                      <div class="form-group">
                        <label for="id_gender">Gender</label>
                        <select
                          class="form-control"
                          :class="{ 'is-invalid': submitted && $v.user.user.id_gender.$error }"
                          name="id_gender"
                          id="id_gender"
                          v-model="user.user.id_gender"
                        >
                          <option value="">SELECT EMPLOYEE GENDER</option>
                          <option value="0">FEMALE</option>
                          <option value="1">MALE</option>
                          <option value="2">NONE BINARY</option>
                        </select>
                        <div v-if="submitted && $v.user.user.id_gender.$error" class="invalid-feedback">
                          <span v-if="!$v.form.id_gender.required">Gender is required.</span>
                        </div>
                      </div>
                    </div>
                    <div class="col-sm-4">
                      <div class="form-group">
                        <label for="num_employeeCompanyPhone">Phone number</label>
                        <input
                          v-mask="'+(##) ###-###-####'"
                          type="text"
                          class="form-control"
                          name="num_employeeCompanyPhone"
                          id="num_employeeCompanyPhone"
                          v-model="user.user.num_employeeCompanyPhone"
                          :class="{ 'is-invalid': submitted && $v.user.user.num_employeeCompanyPhone.$error }"
                        />
                        <div v-if="submitted && $v.user.user.num_employeeCompanyPhone.$error" class="invalid-feedback">
                          <span v-if="!$v.user.user.num_employeeCompanyPhone.required">Phone number is required.</span>
                        </div>
                        <!--<span class="text-muted">e.g "(xx) xxxx-xxxx"</span>-->
                      </div>
                      <div class="form-group">
                        <label for="de_email">Member email</label>
                        <input
                          type="email"
                          class="form-control"
                          :class="{ 'is-invalid': submitted && $v.user.user.de_email.$error }"
                          name="de_email"
                          id="de_email"
                          v-model="user.user.de_email"
                        >
                        <div v-if="submitted && $v.user.user.de_email.$error" class="invalid-feedback">
                          <span v-if="!$v.user.user.de_email.required">Member email is required.</span>
                        </div>
                      </div>
                    </div>

                    <div class="col-sm-12 text-center mt-5">
                      <button type="button" class="btn btn-sm btn-success mr-2" @click="saveProfile">SAVE GENERAL DATA</button>
                      <button type="button" class="btn btn-sm btn-danger ml-2">CANCEL</button>
                    </div>
                    
                  </div>
                </b-tab>

                <b-tab v-if="!(user.user.id_role > 1 && user.user.id_role < 12)">
                  <template v-slot:title>
                    <span class="d-sm-inline-block">
                      <i class="fas fa-share-alt"></i>
                      Company Social Networks
                    </span>
                  </template>
                  <div class="row">
                    <div class="col-lg-12">
                      <div class="form-group position-relative">
                        <label for="validationTooltipUsername">Facebook</label>
                        <div class="input-group">
                          <div class="input-group-prepend">
                            <span id="validationTooltipUsernamePrepend" class="input-group-text">
                              <i class="fab fa-facebook-f"></i>
                            </span>
                          </div>
                          <input
                            id="validationTooltipUsername"
                            type="text"
                            class="form-control"
                            placeholder="Facebook profile link"
                            aria-describedby="validationTooltipUsernamePrepend"
                            v-model="user.user.url_facebook"
                          />
                        </div>
                      </div>

                      <div class="form-group position-relative">
                        <label for="validationTooltipUsername">Twitter</label>
                        <div class="input-group">
                          <div class="input-group-prepend">
                            <span id="validationTooltipUsernamePrepend" class="input-group-text">
                              <i class="fab fa-twitter"></i>
                            </span>
                          </div>
                          <input
                            id="validationTooltipUsername"
                            type="text"
                            class="form-control"
                            placeholder="Twitter profile link"
                            aria-describedby="validationTooltipUsernamePrepend"
                            v-model="user.user.url_twitter"
                          />
                        </div>
                      </div>

                      <div class="form-group position-relative">
                        <label for="validationTooltipUsername">Instagram</label>
                        <div class="input-group">
                          <div class="input-group-prepend">
                            <span id="validationTooltipUsernamePrepend" class="input-group-text">
                              <i class="fab fa-instagram"></i>
                            </span>
                          </div>
                          <input
                            id="validationTooltipUsername"
                            type="text"
                            class="form-control"
                            placeholder="Instagram profile link"
                            aria-describedby="validationTooltipUsernamePrepend"
                            v-model="user.user.url_instagram"
                          />
                        </div>
                      </div>

                      <div class="form-group position-relative">
                        <label for="validationTooltipUsername">LinkedIn</label>
                        <div class="input-group">
                          <div class="input-group-prepend">
                            <span id="validationTooltipUsernamePrepend" class="input-group-text">
                              <i class="fab fa-linkedin-in"></i>
                            </span>
                          </div>
                          <input
                            id="validationTooltipUsername"
                            type="text"
                            class="form-control"
                            placeholder="LinkedIn profile link"
                            aria-describedby="validationTooltipUsernamePrepend"
                            v-model="user.user.url_linkedIn"
                          />
                        </div>
                      </div>

                      <div class="form-group position-relative">
                        <label for="validationTooltipUsername">Youtube</label>
                        <div class="input-group">
                          <div class="input-group-prepend">
                            <span id="validationTooltipUsernamePrepend" class="input-group-text">
                              <i class="fab fa-youtube"></i>
                            </span>
                          </div>
                          <input
                            id="validationTooltipUsername"
                            type="text"
                            class="form-control"
                            placeholder="Youtube profile link"
                            aria-describedby="validationTooltipUsernamePrepend"
                            v-model="user.user.url_youtube"
                          />
                        </div>
                      </div>
                    </div>

                    <div class="col-sm-12 text-center mt-5">
                      <button type="button" class="btn btn-sm btn-success mr-2" @click="saveSocialNetworks">SAVE SOCIAL NETWORKS</button>
                      <button type="button" class="btn btn-sm btn-danger ml-2">CANCEL</button>
                    </div>
                  </div>
                </b-tab>

                <b-tab v-if="!(user.user.id_role > 1 && user.user.id_role < 12)">
                  <template v-slot:title>
                    <span class="d-sm-inline-block">
                      <i class="fas fa-user-lock"></i>
                      Account
                    </span>
                  </template>
                  <div class="row">
                    <div class="col-sm-6">
                      <div class="form-group">
                        <label for="dt_suscriptionDate">Suscription date</label>
                        <input
                          type="text"
                          class="form-control"
                          name="dt_suscriptionDate"
                          id="dt_suscriptionDate"
                          v-model="user.user.dt_suscriptionDate"
                          readonly
                        >
                      </div>
                    </div>
                    <div class="col-sm-6">
                      <div class="form-group">
                        <h5>¿Do you want to cancel this account?</h5>
                        <p>By canceling this account, you will not receive money payback for the rest of the period this account was created. Instead, we encorage you to reach our customer support team at <span class="text-danger"><a href="mailto:customersupport@autoshop.com">customersupport@autoshop.com</a></span>, so that they can help and assists you with any comment you have.</p>
                        <button type="button" class="btn btn-sm btn-danger">UNSUSCRIBE ANYWAY</button>
                      </div>
                    </div>
                  </div>
                </b-tab>
              </b-tabs>
            </div>
          </div>
        </div>
      </div>


      <!--
      <div class="row">
        <div class="col-lg-12">
          <div class="email-leftbar card">
            <img src="@/assets/images/users/avatar-2.jpg" class="rounded-circle" style="border: solid 5px #dfe6e9;" />
            <div class="form-group text-center m-1">
              <input
                class="form-control"
                :class="{ 'is-invalid': submitted && $v.form.nm_company.$error }"
                name="nm_company"
                id="nm_company"
                v-model="form.nm_company"
                type="text"
                placeholder="Organization name"
              />
            </div>
            <div class="form-group text-center m-1">
              <input
                class="form-control"
                :class="{ 'is-invalid': submitted && $v.form.de_companySlogan.$error }"
                name="de_companySlogan"
                id="de_companySlogan"
                v-model="form.de_companySlogan"
                type="text"
                placeholder="Organization slogan"
              />
            </div>
            <div class="form-group text-center m-1">
              <input
                class="form-control"
                :class="{ 'is-invalid': submitted && $v.form.de_companyShortAddress.$error }"
                name="de_companyShortAddress"
                id="de_companyShortAddress"
                v-model="form.de_companyShortAddress"
                type="text"
                placeholder="Organization location"
              />
            </div>


            <div class="card"></div>

            <div class="mail-list mt-4">
              <div class="form-group mt-0">
                <label for="de_companyAbout">About</label>
                <textarea
                  class="form-control"
                  :class="{ 'is-invalid': submitted && $v.form.de_companyAbout.$error }"
                  name="de_companyAbout"
                  id="de_companyAbout"
                  v-model="form.de_companyAbout"
                  rows="5"
                  value=""
                  placeholder="Organization description (short)"
                >
                </textarea>
              </div>

              <div class="card-title" style="border-bottom:solid 1px #C8C8C8"><b>Contact Information</b></div>
              <p>
                <input
                  class="form-control"
                  :class="{ 'is-invalid': submitted && $v.form.de_companyContactEmail.$error }"
                  name="de_companyContactEmail"
                  id="de_companyContactEmail"
                  v-model="form.de_companyContactEmail"
                  type="text"
                  placeholder="Contact email"
                />
                <br>
                <input
                  v-mask="'+(##) ###-###-####'"
                  type="text"
                  class="form-control"
                  :class="{ 'is-invalid': submitted && $v.form.num_companyContactPhone.$error }"
                  name="num_companyContactPhone"
                  id="num_companyContactPhone"
                  v-model="form.num_companyContactPhone"
                  placeholder="Phone number"
                />
                <br>
              </p>

              <div class="card-title" style="border-bottom:solid 1px #C8C8C8"><b>Specialists on</b></div>
              <input-tag v-model="form.de_companyTags" :limit="10" placeholder="10 feats separated by comma."></input-tag>
            </div>
          </div>

          <div class="card email-rightbar">
            <div class="card-body">
              <b-tabs content-class="p-3 text-muted">
                <b-tab active class="border-0">
                  <template v-slot:title>
                    <span class="d-inline-block d-sm-none">
                      <i class="fas fa-home"></i>
                    </span>
                    <span class="d-none d-sm-inline-block">History</span>
                  </template>
                  <div class="form-group">
                    <label >Enter your company description.</label>
                    <vue-editor id="de_companyHistory" v-model="form.de_companyHistory"></vue-editor>
                  </div>
                </b-tab>

                <b-tab>
                  <template v-slot:title>
                    <span class="d-inline-block d-sm-none">
                      <i class="far fa-user"></i>
                    </span>
                    <span class="d-none d-sm-inline-block">Industry</span>
                  </template>
                  <label >Enter a full description about the industry of your company.</label>
                  <vue-editor id="de_companyIndustry" v-model="form.de_companyIndustry"></vue-editor>
                </b-tab>

                <b-tab>
                  <template v-slot:title>
                    <span class="d-inline-block d-sm-none">
                      <i class="far fa-envelope"></i>
                    </span>
                    <span class="d-none d-sm-inline-block">Capabilities</span>
                  </template>
                  <label >Enter a full description about capabilities of your company.</label>
                  <vue-editor id="de_companyCapabilities" v-model="form.de_companyCapabilities"></vue-editor>
                </b-tab>

                <b-tab>
                  <template v-slot:title>
                    <span class="d-inline-block d-sm-none">
                      <i class="fas fa-cog"></i>
                    </span>
                    <span class="d-none d-sm-inline-block">Social Networks</span>
                  </template>
                  <div class="row">
                    <div class="col-lg-12">
                      <div class="form-group position-relative">
                        <label for="validationTooltipUsername">Facebook</label>
                        <div class="input-group">
                          <div class="input-group-prepend">
                            <span id="validationTooltipUsernamePrepend" class="input-group-text">
                              <i class="fab fa-facebook-f"></i>
                            </span>
                          </div>
                          <input
                            id="validationTooltipUsername"
                            type="text"
                            class="form-control"
                            placeholder="Facebook profile link"
                            aria-describedby="validationTooltipUsernamePrepend"
                            v-model="form.url_facebook"
                          />
                        </div>
                      </div>

                      <div class="form-group position-relative">
                        <label for="validationTooltipUsername">Twitter</label>
                        <div class="input-group">
                          <div class="input-group-prepend">
                            <span id="validationTooltipUsernamePrepend" class="input-group-text">
                              <i class="fab fa-twitter"></i>
                            </span>
                          </div>
                          <input
                            id="validationTooltipUsername"
                            type="text"
                            class="form-control"
                            placeholder="Twitter profile link"
                            aria-describedby="validationTooltipUsernamePrepend"
                            v-model="form.url_twitter"
                          />
                        </div>
                      </div>

                      <div class="form-group position-relative">
                        <label for="validationTooltipUsername">Instagram</label>
                        <div class="input-group">
                          <div class="input-group-prepend">
                            <span id="validationTooltipUsernamePrepend" class="input-group-text">
                              <i class="fab fa-instagram"></i>
                            </span>
                          </div>
                          <input
                            id="validationTooltipUsername"
                            type="text"
                            class="form-control"
                            placeholder="Instagram profile link"
                            aria-describedby="validationTooltipUsernamePrepend"
                            v-model="form.url_instagram"
                          />
                        </div>
                      </div>

                      <div class="form-group position-relative">
                        <label for="validationTooltipUsername">LinkedIn</label>
                        <div class="input-group">
                          <div class="input-group-prepend">
                            <span id="validationTooltipUsernamePrepend" class="input-group-text">
                              <i class="fab fa-linkedin-in"></i>
                            </span>
                          </div>
                          <input
                            id="validationTooltipUsername"
                            type="text"
                            class="form-control"
                            placeholder="LinkedIn profile link"
                            aria-describedby="validationTooltipUsernamePrepend"
                            v-model="form.url_linkedIn"
                          />
                        </div>
                      </div>

                      <div class="form-group position-relative">
                        <label for="validationTooltipUsername">Youtube</label>
                        <div class="input-group">
                          <div class="input-group-prepend">
                            <span id="validationTooltipUsernamePrepend" class="input-group-text">
                              <i class="fab fa-youtube"></i>
                            </span>
                          </div>
                          <input
                            id="validationTooltipUsername"
                            type="text"
                            class="form-control"
                            placeholder="Youtube profile link"
                            aria-describedby="validationTooltipUsernamePrepend"
                            v-model="form.url_youtube"
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </b-tab>
              </b-tabs>
            </div>
          </div>
        </div>
      </div>
      -->
    </form>
  </Layout>
</template>

<style type="text/css">
  .socialNetwork{
    font-size: 20px;
  }

  .vue-input-tag-wrapper .input-tag{
    color: #fff !important;
    background-color: #5664d2 !important;
    border-color: #5664d2 !important;

    display: inline-block !important;
    font-weight: 400 !important;
    text-align: center !important;
    vertical-align: middle !important;
    -webkit-user-select: none !important;
    -moz-user-select: none !important;
    user-select: none !important;
    background-color: transparent;
    border: 1px solid transparent;
    transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
    padding: 0.05rem 0.1rem !important;
    font-size: 0.7875rem !important;
    line-height: 1.5 !important;
    border-radius: 0.2rem !important;
  }

  .vue-input-tag-wrapper .input-tag .remove {
    cursor: pointer;
    font-weight: 700;
    color: #fff;
    display: inline-block;
  }

  .mail-list a {
    display: block;
    color: #74788d;
    /* line-height: 24px; */
    padding: 2px 3px;
  }
  .input-group-text{
    background-color: white;
  }

  .nav-tabs .nav-link.active, .nav-tabs .nav-item.show .nav-link{
    font-weight: bolder !important;
    color: #5664d2 !important;
  }
  .circular--portrait {
    position: relative;
    width: 150px;
    height: 150px;
    overflow: hidden;
    border-radius: 50%;
  }
  .circular--portrait img {
    width: 100%;
    height: 150px;
  }

  .circular--portrait--mobile {
    position: relative;
    width: 300px;
    height: 300px;
    overflow: hidden;
    border-radius: 50%;
  }
  .circular--portrait--mobile img {
    width: 100%;
    height: 300px;
  }
</style>